import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\Components\\Common\\Bannerswiper.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\Components\\Homepage\\FrequentQuestions.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\Components\\InformationAbout.js");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\Homepage\\Features.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\Ui_Elements\\CommonCard\\CommonCard.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\Ui_Elements\\OrangeSpan\\OrangeSpan.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\Homepage\\HomeBanner.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\Homepage\\Integrations.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\Homepage\\WhatsOurClient.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\Homepage\\Getstartednow.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\Homepage\\HowVidyalaya.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\Homepage\\WhyUs.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\Ui_Elements\\OrangeCard\\OrangeCard.module.scss");
;
import(/* webpackMode: "eager" */ "D:\\Projects\\Vidyalaya_LMS_Website_NEXTJS\\styles\\LearningPotential.module.scss");
